/*
 * OpenACS Bootstrap 4 SASS Stylesheet
 */

//#openacs-bootstrap4-theme-navbar-main {
//  margin-top: 100px;
//}

html {
  // plain-master does not allow to place class h-100 into the html tag...
  height: 100% !important;
}

//body {
//  padding-top: 165px;
//}

// Required
@import "../src/bootstrap/scss/functions";


// $theme-colors: (
//   Grasgrün
//   "primary": #94C11C
// );

@import "../src/bootstrap/scss/variables";
@import "../src/bootstrap/scss/mixins";

footer {
  background: $gray-900;
}

// https://getbootstrap.com/docs/4.3/getting-started/theming/
$body-bg: #ddd;
$body-color: #000;
$enable-shadows: true;
$enable-gradients: true;
$enable-responsive-font-sizes: true;

$navbar-dark-toggler-color:         rgba(255,255,255, .5);
$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-toggler-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23");
$navbar-dark-toggler-border-color:  rgba(255,255,255, 1);


// Optional
@import "../src/bootstrap/scss/root";
@import "../src/bootstrap/scss/reboot";
@import "../src/bootstrap/scss/type";
@import "../src/bootstrap/scss/images";
@import "../src/bootstrap/scss/code";
@import "../src/bootstrap/scss/grid";
@import "../src/bootstrap/scss/tables";
@import "../src/bootstrap/scss/forms";
@import "../src/bootstrap/scss/buttons";
@import "../src/bootstrap/scss/transitions";
@import "../src/bootstrap/scss/dropdown";
@import "../src/bootstrap/scss/button-group";
@import "../src/bootstrap/scss/input-group";
@import "../src/bootstrap/scss/custom-forms";
@import "../src/bootstrap/scss/nav";
@import "../src/bootstrap/scss/navbar";
@import "../src/bootstrap/scss/card";
@import "../src/bootstrap/scss/breadcrumb";
@import "../src/bootstrap/scss/pagination";
@import "../src/bootstrap/scss/badge";
@import "../src/bootstrap/scss/jumbotron";
@import "../src/bootstrap/scss/alert";
@import "../src/bootstrap/scss/progress";
@import "../src/bootstrap/scss/media";
@import "../src/bootstrap/scss/list-group";
@import "../src/bootstrap/scss/close";
@import "../src/bootstrap/scss/toasts";
@import "../src/bootstrap/scss/modal";
@import "../src/bootstrap/scss/tooltip";
@import "../src/bootstrap/scss/popover";
@import "../src/bootstrap/scss/carousel";
@import "../src/bootstrap/scss/spinners";
@import "../src/bootstrap/scss/utilities";
@import "../src/bootstrap/scss/print";

.button {
  @extend .btn;
  @extend .btn-secondary;
}

input[name="formbutton:ok"] {
  @extend .btn;
  @extend .btn-primary;
}

input[name="formbutton:cancel"] {
  @extend .btn;
  @extend .btn-secondary;
}

.logo.logo-emp {
  position:absolute;
  top: -6px;
  left: 5px;
  z-index:3;
  height:110px
}
.logo.logo-imp {
  height: 75px;
  position:absolute;
  right: 5px;
}
